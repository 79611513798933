<script>
	import { stores } from '@sapper/app';
	import Slide from '../components/Slide.svelte';
	import FromPracticeColumn from '../components/FromPracticeColumn.svelte'
	const { preloading, page, session } = stores();
</script>

<style>* {
  box-sizing: border-box;
}




/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9yb3V0ZXMvaW5kZXguc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0Msc0JBQXNCO0FBQ3ZCIiwiZmlsZSI6InNyYy9yb3V0ZXMvaW5kZXguc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG5cbioge1xuXHRib3gtc2l6aW5nOiBib3JkZXItYm94O1xufVxuXG5cblxuIl19 */</style>

<svelte:head>
	<title>Адвокат Смирнова Е.В. - официальный сайт</title>
</svelte:head>

<Slide path={$page.path} />



<div class="container">
	<div class="main">
		<h1 class="mb-5">
			<p>Официальный сайт адвоката</p>
			<p>Смирновой Евгении Васильевны</p>
		</h1>
		<p>
			Адвокат Смирнова Евгения Васильевна оказывает услуги по защите интересов юридических и физических лиц на территории Российской Федерации.</p>
		<p>
			Многолетний опыт позволяет выступать в защиту клиентов в различных областях правовой деятельности: жилищные споры, наследство, семейные споры, споры с застройщиками. При этом наибольший опыт накоплен в области уголовного и корпоратиного права.</p>
		<div class="flex max520:flex-col w-full">
			<div class="flex flex-col w-1/2 max520:w-full mb-5 items-start">
				<img src="img/icon_corp_law.png" alt="Адвокат Смирнова Е.В., корпоративное право" class="" />
				<ul class="w-full list-square pl-5 mt-5">
					<li>Арбитражные споры</li>
					<li>Защита товарных знаков</li>
					<li>Корпоративные споры</li>
					<li>Анти-монопольные споры</li>
					<li>Доменные споры</li>
					<li>Защита чести и деловой репутации</li>
					<li>Юридическое обслуживание предприятий</li>
					<li>Банкротство</li>
				</ul>
				<!--<a href="https://vk.com/corplawpro">VK corp low</a>-->
			</div>
			<div class="flex flex-col w-1/2 max520:w-full mb-5 items-start">
				<img src="img/icon_crime_law.png" alt="Адвокат Смирнова Е.В., уголовное право" class="" />
				<ul class="w-full list-square pl-5 mt-5">
					<li>УДО, ИТР, замена наказания</li>
					<li>Переквалификация преступления </li>
					<li>Отсрочка наказания</li>
					<li>Преступления против жизни и здоровья</li>
					<li>105 УК убийство</li>
					<li>108 УК превышение самообороны</li>
					<li>115 УК лёгкий вред здоровью</li>
					<li>Адвокат по наркотикам, статьи: 228, 229, 230 УК РФ</li>
				</ul>
			</div>
			
		</div>
	</div>

	<FromPracticeColumn />

</div>